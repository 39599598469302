<template>
  <div class="step_container">
    <a-form-model
      :model="firstForm"
      ref="person_first_form"
      :rules="rules"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <h1 class="step_title">APPLICATION DETAILS 个人信息</h1>
      <!-- 个人信息 -->
      <div class="step_question_container b order_bot_red">
        <h2 class="step_question_title">
          INDIVIDUAL OR SOLE TRADER APPLICATION 申请信息
        </h2>
        <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Title 称谓"
            prop="title"
          >
            <a-select
              @change="
                (value) => {
                  handleSelect(value, 'title');
                }
              "
              v-model="firstForm.title"
            >
              <a-select-option value="Mr"> Mr </a-select-option>
              <a-select-option value="Miss"> Miss </a-select-option>
              <a-select-option value="Ms"> Ms </a-select-option>
              <a-select-option value="Other"> Other </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="First Name"
            prop="first_name"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'first_name');
                }
              "
              v-model="firstForm.first_name"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Last Name 姓"
            prop="last_name"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'last_name');
                }
              "
              v-model="firstForm.last_name"
            />
          </a-form-model-item>
        </div>
        <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Email-Address"
            prop="email_address"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'email_address');
                }
              "
              v-model="firstForm.email_address"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Mobile-Number"
            prop="phone_number"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'phone_number');
                }
              "
              v-model="firstForm.phone_number"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Gender"
            prop="gender"
          >
            <a-select
              @change="
                (value) => {
                  handleSelect(value, 'gender');
                }
              "
              v-model="firstForm.gender"
            >
              <a-select-option value="male"> Male </a-select-option>
              <a-select-option value="female"> Female </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <!-- 生日 国籍 -->
        <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Date of Birth 生日"
            prop="date_of_birth"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'date_of_birth');
                }
              "
              v-model="firstForm.date_of_birth"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Place of Birth 出生地"
            prop="place_of_birth"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'place_of_birth');
                }
              "
              v-model="firstForm.place_of_birth"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            required
            label="Nationality 国籍"
            prop="nationality"
          >
            <a-select
              @change="
                (value) => {
                  handleSelect(value, 'nationality');
                }
              "
              v-model="firstForm.nationality"
            >
              <a-select-opt-group label="Popular Countries">
                <a-select-option
                  v-for="item of popularCountryList"
                  v-bind:key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select-opt-group>
              <a-select-opt-group label="All Countries">
                <a-select-option
                  v-for="item of allCountryList"
                  v-bind:key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
        </div>
        <!-- 护照号吗 -->
        <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width60percent"
            label="Passport Number 护照号码"
          >
            <a-input
              placeholder="If any 如有"
              @change="
                (e) => {
                  handleInput(e, 'passport_num');
                }
              "
              v-model="firstForm.passport_num"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            label="Date of Expiry 失效日期"
          >
            <a-input
              placeholder="If any 如有"
              @change="
                (e) => {
                  handleInput(e, 'passport_expiry');
                }
              "
              v-model="firstForm.passport_expiry"
            />
          </a-form-model-item>
        </div>
        <!-- 证件上传 -->
        <div class="mutiple_form_layout">
          <a-form-model-item
            required
            :style="GetScreenStyle.width60percent"
            label="Driver License or National ID 驾照号码或者身份证号码"
            prop="nationID_driver_license_num"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'nationID_driver_license_num');
                }
              "
              v-model="firstForm.nationID_driver_license_num"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            label="Version Number 版本号"
          >
            <a-input
              placeholder="If any 如有"
              @change="
                (e) => {
                  handleInput(e, 'driver_license_version');
                }
              "
              v-model="firstForm.driver_license_version"
            />
          </a-form-model-item>
        </div>
        <div>
          <div class="step_checkbox_layout">
            <div class="step_sub_title">
              Please upload your ID Vertification 请上传您的ID认证
            </div>
            <ul class="step_ul_style">
              <li>
                Face-to-face channel: with IE MONEY staff assistance to verify
                your documents.
              </li>
              <li>
                Non face-to-channel: without IE MONEY staff assistance to verify
                your documents.
              </li>
            </ul>
          </div>
          <a-form-model-item
            :style="GetScreenStyle.width100percent"
            required
            label="Choose the channel before uploading your proof of identification:"
            prop="prove_channel"
          >
            <a-select
              @change="
                (value) => {
                  handleSelect(value, 'prove_channel');
                }
              "
              v-model="firstForm.prove_channel"
            >
              <a-select-option value="face_to_face"
                >Face-to-face Channel</a-select-option
              >
              <a-select-option value="none_face_to_face"
                >None Face-to-face Channel
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- face_to_face 上传 -->
          <a-form-model-item
            v-if="firstForm.prove_channel == 'face_to_face'"
            :style="GetScreenStyle.width100percent"
            required
            prop="face_to_face_type"
            label="Please choose ONE of the following:"
          >
            <a-radio-group
              class="modify_antd_radio"
              name="face_to_face_type"
              v-model="firstForm.face_to_face_type"
              @change="handleRadio"
            >
              <a-radio :style="radioStyle" :value="1">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    New Zealand/Australia Passport 新西兰/澳大利亚护照 (the copy
                    of passport photo and signature page 护照信息页和签字页)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    Chinese passport 中国护照 (the copy of passport photo,
                    signature page and visa page 护照信息页，签字页和签证页)
                  </li>
                  <li class="li_require">
                    Chinese National ID (the copy of front and back side
                    身份证正反面)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" :value="3">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    Other passport 其他护照 (the copy of passport photo,
                    signature page and visa page 护照信息页，签字页和签证页)
                  </li>
                  <li class="li_require">
                    New Zealand/Australia driver licence (the copy of front and
                    back side 新西兰/澳大利亚驾照正反面)
                  </li>
                </ul>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <!-- none-face-to-face 证件上传 -->
          <a-form-model-item
            v-if="firstForm.prove_channel == 'none_face_to_face'"
            :style="GetScreenStyle.width100percent"
            required
            prop="none_face_to_face_type"
            label="Please choose ONE of the following:"
          >
            <a-radio-group
              class="modify_antd_radio"
              name="none_face_to_face_type"
              v-model="firstForm.none_face_to_face_type"
              @change="handleRadio"
            >
              <a-radio :style="radioStyle" :value="1">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    New Zealand/Australia Passport 新西兰/澳大利亚护照 (the copy
                    of passport photo and signature page 护照信息页和签字页)
                  </li>
                  <li class="li_require">
                    New Zealand/Australia driver licence (the copy of front and
                    back side 新西兰/澳大利亚驾照正反面)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    New Zealand/Australia driver licence (the copy of front and
                    back side 驾照正反面)
                  </li>
                  <li class="li_require">
                    Chinese National ID (the copy of front and back side
                    身份证正反面)
                  </li>
                  <li>
                    Chinese Passport (the copy of passport photo, signature page
                    and visa page 护照信息页，签字页和签证页《如有》)
                  </li>
                </ul>
              </a-radio>
              <a-radio :style="radioStyle" :value="3">
                <ul class="radio_step_ul_style">
                  <li class="li_require">
                    Chinese National ID (the copy of front and back side
                    身份证正反面)
                  </li>
                  <li class="li_require">
                    Chinese bank card (the copy of front and back side
                    银行卡正反面)
                  </li>
                  <li>
                    Chinese Passport (the copy of passport photo, signature page
                    and visa page 护照信息页，签字页和签证页《如有》)
                  </li>
                </ul>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <div
            v-if="
              firstForm.prove_channel == 'face_to_face' &&
              firstForm.face_to_face_type == 1
            "
          >
            <!-- face-to-face 1. nz/au passport value=1 -->
            <AUploadFile
            title="New Zealand/Australia护照信息页+签字页"
            prop="nz_au_passport_file_list"
            :fileList="nz_au_passport_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <!-- face to face 2. cn passport  value=2-->
          <div
            v-if="
              firstForm.prove_channel == 'face_to_face' &&
              firstForm.face_to_face_type == 2
            "
          >
          <AUploadFile
            title="中国护照信息页+签字页"
            prop="cn_passport_file_list"
            :fileList="cn_passport_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国护照签证页"
            prop="cn_passport_visa_file_list"
            :fileList="cn_passport_visa_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国身份证正面"
            prop="cn_identify_front_file_list"
            :fileList="cn_identify_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国身份证反面"
            prop="cn_identify_back_file_list"
            :fileList="cn_identify_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <!-- face-to-face 3. other-passport + nz driver value =3 -->
          <div
            v-if="
              firstForm.prove_channel == 'face_to_face' &&
              firstForm.face_to_face_type == 3
            "
          >
          <AUploadFile
            title="其他护照信息页+签字页"
            prop="other_identify_file_list"
            :fileList="other_identify_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="其他护照签证页"
            prop="other_passport_visa_file_list"
            :fileList="other_passport_visa_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="新西兰/澳大利亚驾照正面"
            prop="nz_au_driver_front_file_list"
            :fileList="nz_au_driver_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="新西兰/澳大利亚驾照反面"
            prop="nz_au_driver_back_file_list"
            :fileList="nz_au_driver_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <!-- none-face-to-face 1. passport + nz driver -->
          <div
            v-if="
              firstForm.prove_channel == 'none_face_to_face' &&
              firstForm.none_face_to_face_type == 1
            "
          >
          <AUploadFile
            title="New Zealand/Australia 护照信息页 + 签字页"
            prop="nz_au_passport_file_list"
            :fileList="nz_au_passport_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="New Zealand/Australia驾照正面"
            prop="nz_au_driver_front_file_list"
            :fileList="nz_au_driver_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="New Zealand/Australia驾照反面"
            prop="nz_au_driver_back_file_list"
            :fileList="nz_au_driver_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <!-- none-face-to-face 2. passport + nz driver -->
          <div
            v-if="
              firstForm.prove_channel == 'none_face_to_face' &&
              firstForm.none_face_to_face_type == 2
            "
          >
          <AUploadFile
            title="New Zealand/Australia 驾照正面"
            prop="nz_au_driver_front_file_list"
            :fileList="nz_au_driver_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="New Zealand/Australia 驾照反面"
            prop="nz_au_driver_back_file_list"
            :fileList="nz_au_driver_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国身份证正面"
            prop="cn_identify_front_file_list"
            :fileList="cn_identify_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国身份证反面"
            prop="cn_identify_back_file_list"
            :fileList="cn_identify_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国护照信息页+签字页(如有)"
            prop="cn_passport_not_required_file_list"
            :fileList="cn_passport_not_required_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国护照签证页(如有)"
            prop="cn_passport_not_required_visa_file_list"
            :fileList="cn_passport_not_required_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
          <!-- none-face-to-face 3. passport + nz driver -->
          <div
            v-if="
              firstForm.prove_channel == 'none_face_to_face' &&
              firstForm.none_face_to_face_type == 3
            "
          >
          <AUploadFile
            title="中国身份证正面"
            prop="cn_identify_front_file_list"
            :fileList="cn_identify_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
             <AUploadFile
            title="中国身份证反面"
            prop="cn_identify_back_file_list"
            :fileList="cn_identify_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国银行卡正面"
            prop="cn_bank_front_file_list"
            :fileList="cn_bank_front_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国银行卡反面"
            prop="cn_bank_back_file_list"
            :fileList="cn_bank_back_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国护照信息页+签字页(如有)"
            prop="cn_passport_not_required_file_list"
            :fileList="cn_passport_not_required_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
            <AUploadFile
            title="中国护照签证页(如有)"
            prop="cn_passport_not_required_visa_file_list"
            :fileList="cn_passport_not_required_visa_file_list"
            @beforeUpload="beforeUpload"
            @handleFileChange="handleFileList"
            />
          </div>
        </div>
        <!-- 税务信息 -->
        <div class="step_checkbox_layout">
          <div>
            <!-- 新西兰本地税务信息 -->
            <div class="step_sub_title">
              Are you an New Zealand Tax Resident? 您是否是新西兰税务居民？
            </div>
            <ul class="step_ul_style">
              <li>
                If NO, you must provide your tax residence details in the other
                countries at below
              </li>
              <li>若不是，您需要提供你其他国家的税务信息</li>
            </ul>
            <div class="step_checkbox_layout">
              <a-radio-group
                class="modify_antd_radio_id"
                @change="handleRadio"
                v-model="firstForm.is_nz_tax_resident"
                name="is_nz_tax_resident"
              >
                <a-radio :value="true"> Yes 是 </a-radio>
                <a-radio :value="false"> No 否 </a-radio>
              </a-radio-group>
            </div>
            <div class="marginT_10" v-if="is_nz_tax_resident == true">
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                required
                label="IRD/Tax Number 税号"
                prop="nz_ird_num"
              >
                <a-input
                  @change="
                    (e) => {
                      handleInput(e, 'nz_ird_num');
                    }
                  "
                  :value="nz_ird_num"
                />
              </a-form-model-item>
              <div class="step_sub_title">
                Are you a tax resident of another country?
                您是否还有其他国家的税务信息？
              </div>
              <ul class="step_ul_style">
                <li>
                  If YES, please list your tax residence details in all the
                  other countries below
                </li>
                <li>若是，请提供其他国家的税务信息</li>
              </ul>
              <div class="step_checkbox_layout">
                <a-form-model-item
                  :style="GetScreenStyle.width100percent"
                  prop="has_other_country_tax"
                >
                  <a-radio-group
                    class="modify_antd_radio_id"
                    @change="handleRadio"
                    v-model="firstForm.has_other_country_tax"
                    name="has_other_country_tax"
                  >
                    <a-radio :value="true"> Yes 是 </a-radio>
                    <a-radio :value="false"> No 否 </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </div>
            </div>
            <div
              class="step_checkbox_layout"
              v-if="
                is_nz_tax_resident == false ||
                (is_nz_tax_resident == true && has_other_country_tax == true)
              "
            >
              <!-- 是否有其他国家的 税务信息  -->
              <a-tabs
                v-model="activeKey"
                type="editable-card"
                @edit="onEdit"
                :hideAdd="panes.length && panes >= 3"
              >
                <a-tab-pane
                  v-for="pane in panes"
                  :key="pane.key"
                  :tab="pane.title"
                  :closable="pane.closable"
                >
                <a-form-model-item
                :style="GetScreenStyle.width100percent"
                prop="panes"
                >
                  <div class="mutiple_form_layout">
                    <a-form-model-item
                      :style="GetScreenStyle.width45percent"
                      required
                      label="Country of Tax Residence 税务国家"
                    >
                      <a-select
                        @change="
                          (value) => {
                            handleOtherTaxCountry(value, pane.key);
                          }
                        "
                        :value="pane.content.other_tax_contry"
                      >
                        <a-select-opt-group label="Popular Countries">
                          <a-select-option
                            v-for="item of popularCountryList"
                            v-bind:key="item.code"
                            :value="item.code"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select-opt-group>
                        <a-select-opt-group label="All Countries">
                          <a-select-option
                            v-for="item of allCountryList"
                            v-bind:key="item.code"
                            :value="item.code"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select-opt-group>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item
                      :style="GetScreenStyle.width45percent"
                      label="Tax Number 税号"
                    >
                      <a-input
                        @change="
                          (e) => {
                            handleOtherTaxNum(e, pane.key);
                          }
                        "
                        :value="pane.content.other_tax_num"
                      />
                    </a-form-model-item>
                  </div>
                </a-form-model-item>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </div>
        <!-- <a-form-model-item required label="Date of Birth">
          <a-date-picker
            :value="dob"
            @change="(e)=>{handleDatePick(e,'dob')}"
            placeholder=""
            style="width: 100%;"
          />
        </a-form-model-item> -->
      </div>
      <!-- 地址信息 -->
      <div class="step_question_container border_bot_red">
        <h2 class="step_question_title">
          ADDRESS AND CONTACT DETAILS 地址信息
        </h2>
        <div>
          <a-form-model-item
            required
            label="Residential Address(No Po BOX) 居住地址"
            prop="po_box_address"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'po_box_address');
                }
              "
              :value="po_box_address"
            />
          </a-form-model-item>
          <div class="mutiple_form_layout">
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              label="City 城市"
              prop="live_city"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'live_city');
                  }
                "
                :value="live_city"
              />
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              prop="live_country"
              label="Country 国家"
            >
              <a-select
                @change="
                  (value) => {
                    handleSelect(value, 'live_country');
                  }
                "
                :value="live_country"
              >
                <a-select-opt-group label="Popular Countries">
                  <a-select-option
                    v-for="item of popularCountryList"
                    v-bind:key="item.code"
                    :value="item.code"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select-opt-group>
                <a-select-opt-group label="All Countries">
                  <a-select-option
                    v-for="item of allCountryList"
                    v-bind:key="item.code"
                    :value="item.code"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              required
              prop="post_code"
              label="PostCode 邮政编码"
            >
              <a-input
                @change="
                  (e) => {
                    handleInput(e, 'post_code');
                  }
                "
                :value="post_code"
              />
            </a-form-model-item>
          </div>
          <!-- 上传地址证明 -->
          <div class="step_checkbox_layout">
            <div class="step_sub_title">
              Please upload your address verification 请上传您的地址认证
            </div>
            <a-form-model-item required prop="addressProveType">
              <a-radio-group
                class="modify_antd_radio"
                name="addressProveType"
                :value="addressProveType"
                @change="handleRadio"
              >
                <a-radio :style="radioStyle" :value="1">
                  Bank Statements and Bank Correspondence 银行对账单和银行通信
                </a-radio>
                <a-radio :style="radioStyle" :value="2">
                  Utility Bill 各种账单
                </a-radio>
                <a-radio :style="radioStyle" :value="3">
                  Government Agency Correspondence (Council Rates, etc.)
                  政府机构通信</a-radio
                >
                <a-radio :style="radioStyle" :value="4">
                  Electoral Commission Correspondence 选举委员会函件</a-radio
                >
                <a-radio :style="radioStyle" :value="5">
                  Tenancy Agreement 租赁协议</a-radio
                >
                <a-radio :style="radioStyle" :value="6">
                  Vehicle Registration 车辆登记</a-radio
                >
              </a-radio-group>
            </a-form-model-item>
            <div class="step_checkbox_layout">
              <div v-if="addressProveType == 1">
                <ul class="step_ul_style">
                  <li>
                    This must be a statement from a bank/IRD that has been
                    posted to the client and is dated within the last 3 months.
                    这必须是银行/
                    IRD的记录，该记录信件必须是最近三个月内通过信件发送给注册人的。
                  </li>
                  <li>
                    Bank correspondence must confirm a business relationship
                    exists between the bank and the client.
                    银行通信必须是能确认银行与注册人之间存在业务关系的
                  </li>
                  <li>
                    Printed information from online account or email
                    correspondence is not acceptable unless it is JP/lawyer
                    certified. 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
                  <a-form-model-item
                    :style="GetScreenStyle.width100percent"
                    prop="bank_stament_address_file_list"
                  >
                    <a-upload
                      :before-upload="beforeUpload"
                      action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                      list-type="picture"
                      name="userfile"
                      @change="
                        (e) => {
                          handleFileList(e, 'bank_stament_address_file_list');
                        }
                      "
                      :file-list="bank_stament_address_file_list"
                    >
                      <a-button> <a-icon type="upload" /> upload </a-button>
                    </a-upload>
                  </a-form-model-item>
                  <div class="step_notice_text marginT_10">
                    The file's fomat supports JPG,PNG,JPEG,PDF or any video
                    types and the size is not exceed 20MB
                  </div>
                </div>
              </div>
              <div v-if="addressProveType == 2">
                <ul class="step_ul_style">
                  <li>
                    This must be a statement for utility service at a fixed
                    location that has been posted to the client and is dated
                    within the last 3 months.
                    这必须最近三个月内通过邮寄发送去注册人的账单。
                  </li>
                  <li>
                    Fixed services include electricity, water, gas, home
                    internet/landlines and Sky.
                    服务账单包括电力，水，煤气，家庭互联网/固定电话和电视。
                  </li>
                  <li>
                    Fixed services include electricity, water, gas, home
                    internet/landlines and Sky.
                    服务账单包括电力，水，煤气，家庭互联网/固定电话和电视。
                  </li>
                  <li>
                    Printed information from online account or email
                    correspondence is not acceptable unless it is JP/lawyer
                    certified. . 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
                  <a-form-model-item
                    :style="GetScreenStyle.width100percent"
                    prop="utility_address_file_list"
                  >
                    <a-upload
                      :before-upload="beforeUpload"
                      action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                      list-type="picture"
                      name="userfile"
                      @change="
                        (e) => {
                          handleFileList(e, 'utility_address_file_list');
                        }
                      "
                      :file-list="utility_address_file_list"
                    >
                      <a-button> <a-icon type="upload" /> upload </a-button>
                    </a-upload>
                  </a-form-model-item>
                  <div class="step_notice_text marginT_10">
                    The file's fomat supports JPG,PNG,JPEG,PDF or any video
                    types and the size is not exceed 20MB
                  </div>
                </div>
              </div>
              <div v-if="addressProveType == 3">
                <ul class="step_ul_style">
                  <li>
                    This must be a statement, letter or invoice that has been
                    posted to the client from a government agency and is dated
                    within the last 3 months.
                    这必须最近三个月内来自政府机构通过邮寄发送去注册人的声明/信件/发票。
                  </li>
                  <li>
                    This includes confirmation of bond lodgements with the
                    Department of Building and Housing.
                    这可以是来自于建筑和住房部押金投管确认信
                  </li>
                  <li>
                    This includes confirmation of bond lodgements with the
                    Department of Building and Housing.
                    这可以是来自于建筑和住房部押金投管确认信
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
                  <a-form-model-item
                    :style="GetScreenStyle.width100percent"
                    prop="government_correspondence_address_file_list"
                  >
                    <a-upload
                      :before-upload="beforeUpload"
                      action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                      list-type="picture"
                      name="userfile"
                      @change="
                        (e) => {
                          handleFileList(
                            e,
                            'government_correspondence_address_file_list'
                          );
                        }
                      "
                      :file-list="government_correspondence_address_file_list"
                    >
                      <a-button> <a-icon type="upload" /> upload </a-button>
                    </a-upload>
                  </a-form-model-item>
                  <div class="step_notice_text marginT_10">
                    The file's fomat supports JPG,PNG,JPEG,PDF or any video
                    types and the size is not exceed 20MB
                  </div>
                </div>
              </div>
              <div v-if="addressProveType == 4">
                <ul class="step_ul_style">
                  <li>
                    This must be a letter that has been posted to the client and
                    is dated within the last 3 months.
                    这必须是三个月内通过邮寄发送至注册人的信件
                  </li>
                  <li>
                    unless it is JP/lawyer certified. . 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
                  <a-form-model-item
                    :style="GetScreenStyle.width100percent"
                    prop="commission_correspondence_address_file_list"
                  >
                    <a-upload
                      :before-upload="beforeUpload"
                      action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                      list-type="picture"
                      name="userfile"
                      @change="
                        (e) => {
                          handleFileList(
                            e,
                            'commission_correspondence_address_file_list'
                          );
                        }
                      "
                      :file-list="commission_correspondence_address_file_list"
                    >
                      <a-button> <a-icon type="upload" /> upload </a-button>
                    </a-upload>
                  </a-form-model-item>
                  <div class="step_notice_text marginT_10">
                    The file's fomat supports JPG,PNG,JPEG,PDF or any video
                    types and the size is not exceed 20MB
                  </div>
                </div>
              </div>
              <div v-if="addressProveType == 5">
                <ul class="step_ul_style">
                  <li>
                    This must be a signed tenancy agreement dated within the
                    last 3 months. 这必须是过去3个月内签署的租赁协议
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
                  <a-form-model-item
                    :style="GetScreenStyle.width100percent"
                    prop="tenancy_agreement_address_file_list"
                  >
                    <a-upload
                      :before-upload="beforeUpload"
                      action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                      list-type="picture"
                      name="userfile"
                      @change="
                        (e) => {
                          handleFileList(
                            e,
                            'tenancy_agreement_address_file_list'
                          );
                        }
                      "
                      :file-list="tenancy_agreement_address_file_list"
                    >
                      <a-button> <a-icon type="upload" /> upload </a-button>
                    </a-upload>
                  </a-form-model-item>
                  <div class="step_notice_text marginT_10">
                    The file's fomat supports JPG,PNG,JPEG,PDF or any video
                    types and the size is not exceed 20MB
                  </div>
                </div>
              </div>
              <div v-if="addressProveType == 6">
                <ul class="step_ul_style">
                  <li>
                    This must be a current vehicle registration that has been
                    posted to the client and is dated within the last 3 months.
                    这必须是三个月内通过邮寄发送给注册人的车辆登记信件
                  </li>
                  <li>
                    Printed information from online account or email
                    correspondence is not acceptable unless it is JP/lawyer
                    certified. . 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
                  <a-form-model-item
                    :style="GetScreenStyle.width100percent"
                    prop="vehicle_register_address_file_list"
                  >
                    <a-upload
                      :before-upload="beforeUpload"
                      action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                      list-type="picture"
                      name="userfile"
                      @change="
                        (e) => {
                          handleFileList(
                            e,
                            'vehicle_register_address_file_list'
                          );
                        }
                      "
                      :file-list="vehicle_register_address_file_list"
                    >
                      <a-button> <a-icon type="upload" /> upload </a-button>
                    </a-upload>
                  </a-form-model-item>
                  <div class="step_notice_text marginT_10">
                    The file's fomat supports JPG,PNG,JPEG,PDF or any video
                    types and the size is not exceed 20MB
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 工作情况 -->
      <div class="step_question_container border_bot_green">
        <h2 class="step_question_title">
          EMPLOYMENT/BUSINESS DETAILS (Optional) 工作情况/商业详情
        </h2>
        <a-form-model-item
          required
          prop="employer_company_name"
          label="Employer/Trading Name 公司名称"
        >
          <a-input
            @change="
              (e) => {
                handleInput(e, 'employer_company_name');
              }
            "
            :value="employer_company_name"
          />
        </a-form-model-item>
        <a-form-model-item
          label="Business Physical Address(No Po BOX) 公司地址"
        >
          <a-input
            @change="
              (e) => {
                handleInput(e, 'employer_company_address');
              }
            "
            :value="employer_company_address"
          />
        </a-form-model-item>
        <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            label="City 城市"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'employer_company_city');
                }
              "
              :value="employer_company_city"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            label="Country 国家"
          >
            <a-select
              @change="
                (value) => {
                  handleSelect(value, 'employer_company_country');
                }
              "
              :value="employer_company_country"
            >
              <a-select-opt-group label="Popular Countries">
                <a-select-option
                  v-for="item of popularCountryList"
                  v-bind:key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select-opt-group>
              <a-select-opt-group label="All Countries">
                <a-select-option
                  v-for="item of allCountryList"
                  v-bind:key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width30percent"
            label="PostCode 邮政编码"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'employer_company_post_code');
                }
              "
              :value="employer_company_post_code"
            />
          </a-form-model-item>
        </div>
        <div class="mutiple_form_layout">
          <a-form-model-item
            prop="employer_job_title"
            required
            :style="GetScreenStyle.width30percent"
            label="Job Title 职位"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'employer_job_title');
                }
              "
              :value="employer_job_title"
            />
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width60percent"
            label="Business Phone Number 商务电话"
          >
            <a-input
              @change="
                (e) => {
                  handleInput(e, 'employer_phone_number');
                }
              "
              :value="employer_phone_number"
            />
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import AUploadFile from '../../../../components/AUploadFile'

export default {
  created () {
  },
  components: {
    AUploadFile
  },
  methods: {
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload jpeg/png/pdf file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('Image must smaller than 20MB!')
      }
      return isJpgOrPng && isLt2M
    },
    handleFileList ({ fileList }, content) {
      console.log({ fileList }, content)
      if (fileList.length <= 0) {
        this.ChangeObjectAction({ content, value: [] }
        )
        return
      }
      const lastestFile = fileList[fileList.length - 1]
      if (!lastestFile.type) {
        return
      }

      const isValidType = lastestFile.type === 'image/jpeg' || lastestFile.type === 'image/png' || lastestFile.type === 'application/pdf'
      fileList = fileList.slice(-3)
      if (!isValidType) {
        return
      }
      const isLt2M = lastestFile.size / 1024 / 1024 < 20
      if (!isLt2M) {
        return
      }
      const _fileList = fileList.map(item => ({
        ...item,
        url: item.response,
        originFileObj: null
      }))
      const __fileList = _fileList.filter(item => ((item.type && item.type) === 'image/jpeg' || 'image/png' || 'application/pdf'))
      this.ChangeObjectAction({ content, value: __fileList })
    },
    handleOtherTaxCountry (val, param) {
      const index = this.panes.findIndex(item => item.key === param)
      this.panes[index].content.other_tax_contry = val
      this.ChangeObjectAction({ content: 'panes', value: this.panes })
    },
    handleOtherTaxNum (e, param) {
      const val = e.target.value
      const index = this.panes.findIndex(item => item.key === param)
      this.panes[index].content.other_tax_num = val
      this.ChangeObjectAction({ content: 'panes', value: this.panes })
    },
    handleCheck (e) {
      const { checked, name } = e.target
      this.ChangeFormAction({ content: name, value: checked })
    },
    onEdit (targetKey, action) {
      this[action](targetKey)
    },
    add () {
      const _panes = this.panes
      _panes.push({ title: 'Tax Number 税务信息 # ' + (this.getLastPanesKey + 2), content: { other_tax_contry: null, other_tax_num: null }, key: this.getLastPanesKey + 1 })
      this.activeKey = this.getLastPanesKey
      this.ChangeObjectAction({ content: 'panes', value: _panes })
    },
    remove (targetKey) {
      let activeKey = this.activeKey
      let _panes = {}
      let lastIndex
      this.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })
      _panes = this.panes.filter(pane => pane.key !== targetKey)
      if (lastIndex >= 0) {
        activeKey = this.panes[lastIndex].key
      } else {
        activeKey = this.panes[0].key
      }
      this.activeKey = activeKey
      this.ChangeObjectAction({ content: 'panes', value: _panes })
    },
    handleRadioHasOtherTax (e) {
      const { value } = e.target
      this.has_other_country_tax = value
    },
    onAddTypeSelect (e) {
      const { value } = e.target
      this.addressProveType = value
    },
    handleInput (e, content) {
      const { value } = e.target
      this.ChangeFormAction({ content, value })
    },
    handleRadio (e) {
      const { name, value } = e.target
      // console.log(name, value)
      this.ChangeFormAction({ content: name, value })
      // this.is_nz_tax_resident = value
    },
    handleSelect (value, content) {
      this.ChangeFormAction({ content, value })
    },
    handleDatePick (value, content) {
      this.ChangeFormAction({ content, value })
    },
    ...mapActions('personStepOne', ['ChangeFormAction', 'ChangeObjectAction'])
  },
  data () {
    return {
      activeKey: 0,
      // panes: [
      //   { title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }
      // ],
      radioStyle: {
        lineHeight: '30px',
        width: '100%',
        marginBottom: '10px'
      },
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.person_first_form.validate(valid => {
      if (valid) {
        console.log('submit')
        next()
      } else {
        console.log('error submit!!')
        return false
      }
    })
    next()
  },
  computed: {
    firstForm () {
      return this.$store.state.personStepOne
    },
    rules () {
      return this.$store.state.personStepOne.rules
    },
    getLastPanesKey () {
      return this.panes[this.panes.length - 1].key || 0
    },
    ...mapState('personStepOne', ['title', 'first_name', 'last_name', 'email_address', 'phone_number', 'gender', 'passport_num', 'date_of_birth', 'place_of_birth', 'nationality', 'passport_expiry', 'driver_license_num', 'driver_license_version', 'is_upload_passport', 'is_upload_driver_license_bank_card', 'is_upload_nationalID', 'is_nz_tax_resident', 'cn_passport_visa_file_list', 'po_box_address', 'nz_ird_num', 'live_city', 'live_country', 'post_code', 'addressProveType', 'other_passport_visa_file_list', 'employer_company_name', 'employer_company_address', 'employer_company_city', 'employer_company_country', 'employer_company_post_code', 'employer_job_title', 'employer_phone_number', 'passport_file_list', 'nationalID_file_list', 'driver_license_file_list', 'bank_stament_address_file_list', 'utility_address_file_list', 'government_correspondence_address_file_list', 'commission_correspondence_address_file_list', 'tenancy_agreement_address_file_list', 'vehicle_register_address_file_list', 'has_other_country_tax', 'panes', 'nz_au_passport_file_list', 'nz_au_driver_front_file_list', 'nz_au_driver_back_file_list', 'cn_passport_file_list', 'cn_identify_file_list', 'cn_identify_front_file_list', 'cn_identify_back_file_list', 'other_identify_file_list', 'cn_bank_front_file_list', 'cn_bank_back_file_list', 'cn_passport_not_required_file_list', 'cn_passport_not_required_visa_file_list']),
    ...mapGetters('countrySelection', ['popularCountryList', 'allCountryList']),
    ...mapGetters('layout', ['GetScreenStyle'])
  }
}
</script>

<style scoped>
.step_upload_box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}
.mutiple_form_layout {
  display: flex;
  justify-content: space-between;
}
.step_container {
  background: rgb(248, 248, 248);
}
.step_notice_text {
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
.step_checkbox_layout {
  margin-top: 20px;
}

.ant-form-item-label .ant-form-item-required {
  font-size: 16px;
  color: red;
}
.step_sub_title {
  margin: 20px 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.radio_step_ul_style {
  padding-left: 5px;
  font-size: 16px;
  font-weight: 300;
}
.radio_step_ul_style li {
  line-height: 24px;
}

.step_ul_style {
  padding-left: 20px;
  font-size: 16px;
  font-weight: 300;
}
.step_ul_style li {
  line-height: 24px;
}
.marginT_10 {
  margin-top: 10px;
}
.border_bot_red {
  border-bottom: 2px solid #f32e59;
}
.border_bot_green {
  border-bottom: 2px solid #2baf6f;
}
.modify_antd_radio >>> .ant-radio-inner {
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-wrapper {
  white-space: pre-wrap;
  display: flex;
}
.modify_antd_radio >>> .ant-radio-inner::after {
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.modify_antd_radio >>> .ant-radio-checked::after {
  height: 25px;
}
.step_ul_style li::before {
  content: "\2022";
  color: #999;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1rem;
}
.step_ul_style .li_require::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1rem;
}
.ant-form >>> label {
  font-size: 15px;
}
@media only screen and (max-width: 768px) {
  .mutiple_form_layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
