<template>
    <div class="step_upload_box marginT_10">
        <div>{{title}}</div>
        <a-form-model-item
          :style= width100percent
          :prop="prop"
        >
          <a-upload
            action="https://fx.iemoney.co.nz/api/submit-cdd-file"
            list-type="picture"
            name="userfile"
            :before-upload="beforeUpload"
            @change="
              (e) => {
                handleFileChange(e);
              }
            "
            :file-list="fileList"
          >
            <a-button> <a-icon type="upload" /> upload </a-button>
          </a-upload>
        </a-form-model-item>
        <div class="step_notice_text marginT_10">
          The file's fomat supports JPG,PNG,JPEG,PDF or any video types
          and the size is not exceed 20MB
        </div>
      </div>
</template>

<script>
export default {
  methods: {
    beforeUpload (file) {
      this.$emit('beforeUpload', file)
    },
    handleFileChange (e) {
      this.$emit('handleFileChange', e, this.prop)
    }
  },
  data () {
    return {
      width100percent: {
        width: '100%'
      }
    }
  },
  props: {
    title: {
      type: String
    },
    prop: {
      type: String
    },
    fileList: {
      type: Array
    }
  }
}
</script>

<style scoped>
.step_upload_box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}
.marginT_10 {
  margin-top: 10px;
}
.step_notice_text {
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
</style>
